import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Path from '../../services/path';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import Heading from '@bfbs/storybook/dist/components/atoms/heading/heading';
import PodcastRecent from '@bfbs/storybook/dist/components/molecules/podcast-recent/podcast-recent';
import Accordion from '@bfbs/storybook/dist/components/molecules/accordion/accordion';
import Icon from '@bfbs/storybook/dist/components/atoms/icon/icon';

const PodcastSeries = ({ data: { allFeedBfbsPodcastsItems }, location }) => {
  const latestEpisode = allFeedBfbsPodcastsItems.nodes[0];
  const moreEpisodes = allFeedBfbsPodcastsItems.nodes.slice(1);
  for (let i = 0; i < moreEpisodes.length; i++) {
    moreEpisodes[i].subtitle = moreEpisodes[i].isoDate;
    moreEpisodes[i].icon = 'play-btn-blue-white';
    moreEpisodes[i].shareTitle = 'Share this';
    moreEpisodes[i].shareUrl = typeof location !== 'undefined' ? location.href : null;
    moreEpisodes[i].downloadUrl = moreEpisodes[i].downloadable ? moreEpisodes[i].enclosure.url : null;
    moreEpisodes[i].playUrl = Path.getPodcastPlayerUrl(moreEpisodes[i].id)
  }
  const episodesPerPage = 20;
  const [episodes, setEpisodes] = useState(moreEpisodes);
  const [episodesShown, setEpisodesShown] = useState(moreEpisodes.slice(0, episodesPerPage));
  const [pagination, setPagination] = useState(1);
  const [hiddenClass, setHiddenClass] = useState('hidden');
  const numberOfPages = Number((moreEpisodes.length / episodesPerPage).toFixed(0));
  const results = typeof document !== 'undefined' ? document.querySelector('.more-episodes') : null;

  function handleScroll() {
    if (results) {
     results.scrollIntoView({
       behavior: 'smooth',
     });
    }
  }

  useEffect(() => {
    setEpisodesShown(episodes.slice((pagination - 1) * episodesPerPage, (pagination - 1) * episodesPerPage + episodesPerPage));
  }, [pagination]);

  function handleFilterChange() {
    setEpisodes(episodes.reverse());
    setEpisodesShown(episodes.slice((pagination - 1) * episodesPerPage, (pagination - 1) * episodesPerPage + episodesPerPage));
  }

  function handlePaginationChange(page) {
    handleScroll();
    setPagination(page);
  }

  function handlePreviousPage() {
    handleScroll();
    if (pagination !== 1) {
      setPagination(pagination - 1);
    }
  }

  function handleNextPage() {
    handleScroll();
    if (pagination > 2) {
      setHiddenClass('');
    }

    if (pagination < numberOfPages) {
      setPagination(pagination + 1);
    }
  }
  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'Podcasts', path: Path.podcastList },
    { name: latestEpisode.series_title, path: null },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title={latestEpisode.series_title} />
      <div className="o-podcast-show">
        <SlimlineBannerBlock background="background--tertiary-two" heading="Podcasts & Specials" />

        <div className="o-podcast-show__container container container--max-width">
          <div className="container__limited container__limited--large">
            <PodcastRecent
                date={latestEpisode.isoDate}
                description={latestEpisode.content}
                downloadUrl={latestEpisode.downloadable ? latestEpisode.enclosure.url : null}
                playUrl={Path.getPodcastPlayerUrl(latestEpisode.id)}
                summary={latestEpisode.itunes.summary}
                thumbnail={latestEpisode.itunes.image}
                title={latestEpisode.title}
                shareUrl={location.href}
                shareTitle={latestEpisode.title}
                appleUrl={latestEpisode.external_links ? latestEpisode.external_links.itunes : null }
                googleUrl={latestEpisode.external_links ? latestEpisode.external_links.google : null }
                spotifyUrl={latestEpisode.external_links ? latestEpisode.external_links.spotify : null }
            />
          </div>
          <div className="container__limited">
            {episodes.length && (
                <div className="more-episodes">
                  <Heading>More episodes</Heading>
                  <div className="o-podcast-show__filters">
                    <span>Order by:</span>
                    <div className="o-podcast-show__filters__item">
                      <input
                          type="radio"
                          id="newest"
                          name="order"
                          value="newest"
                          onChange={handleFilterChange}
                          defaultChecked
                      />
                      <label htmlFor="newest">Newest</label>
                    </div>
                    <div className="o-podcast-show__filters__item">
                      <input type="radio" id="oldest" name="order" value="oldest" onChange={handleFilterChange} />
                      <label htmlFor="oldest">Oldest</label>
                    </div>
                  </div>
                  <Accordion accordionData={episodesShown} />
                  {numberOfPages > 1 && (
                      <div className="o-podcast-show__pagination">
                        {/* eslint-disable-next-line react/button-has-type,react/no-this-in-sfc */}
                        <button onClick={handlePreviousPage} className={pagination === 1 ? 'disabled' : ''}>
                      <span>
                        Prev
                        <span className="hidden-mobile"> page</span>
                      </span>
                          <Icon icon="arrow-down" size="xs" />
                        </button>
                        <ul className="o-podcast-show__pagination-numbers">
                          {Array.apply(0, Array(numberOfPages)).map((x, i) => {
                            const activeClass = i + 1 === pagination ? 'active' : '';
                            const addHidenClass =
                                numberOfPages > 2 && i > 2 && i < numberOfPages - 1 ? ` ${hiddenClass}` : '';
                            const displayDottedButton = numberOfPages > 4 && i + 1 === numberOfPages;
                            return (
                                <>
                                  {displayDottedButton && (
                                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                                      <li
                                          className={`o-podcast-show__pagination__show-more${hiddenClass === '' ? ' hidden' : ''}`}
                                          onClick={() => setHiddenClass('')}
                                      >
                                        ...
                                      </li>
                                  )}
                                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                  <li
                                      className={`${activeClass}${addHidenClass}`}
                                      onClick={() => handlePaginationChange(i + 1)}
                                  >
                                    {i + 1}
                                  </li>
                                </>
                            );
                          })}
                        </ul>
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button onClick={handleNextPage} className={pagination === numberOfPages ? 'disabled' : ''}>
                          <span>
                            Next
                            <span className="hidden-mobile"> page</span>
                          </span>
                          <Icon icon="arrow-down" size="xs" />
                        </button>
                      </div>
                  )}
                </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PodcastSeries;

export const podcastQuery = graphql`
  query SeriesPodcasts($series: [String!]) {
    allFeedBfbsPodcastsItems(sort: { fields: isoDate, order: DESC }, filter: { series: { in: $series } }) {
      nodes {
        id
        series
        series_title
        title
        content
        isoDate(formatString: "DD/MM/YYYY")
        itunes {
          image
          summary
        }
        enclosure {
          url
        }
        downloadable
        external_links {
          google
          itunes
          spotify
        }
      }
    }
  }
`;
